import React, { Component } from "react";
import {
  companyName,
} from "../helpers/GlobalVars";
import { Col, Row, Container } from "react-bootstrap";
import "../index.css";
import { withTranslation } from "react-i18next";

class Home extends Component {

  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
    };
  }

  componentWillMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  // make sure to remove the listener
  // when the component is not mounted anymore
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    const { t } = this.props;
    const { width } = this.state;
    const isFullScreen = width >= 1000;
    let render_text;
    let brands_box;
    if (!isFullScreen) {
      render_text = (
        <Row>
          <Col className={"left_alignment text_margin_left"}>
            
            <p>{t("about_henri1")}</p>
            <p>{t("about_henri2")}</p>
            <p>{t("about_henri3")}</p>
            <p>{t("about_henri4")}</p>
            <p>{t("about_henri5")}</p>
            <p>{t("about_henri6")}</p>

            <br />
            <img className={"left_alignment"} src="/label.jpg" alt="Label" />

            <Container>
              <Row>
                <Col>
                  <img
                    className={"about_pic"}
                    src="/about/about1.jpg"
                    alt="Home Picture"
                  />
                </Col>
                <Col>
                  <img
                    className={"about_pic"}
                    src="/about/about3.jpg"
                    alt="Home Picture"
                  />
                </Col>
                <Col>
                  <img
                    className={"about_pic_larger"}
                    src="/about/about5.jpg"
                    alt="Home Picture"
                  />
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      );
      brands_box = (
        <Container className={"padding_large_background"}>
          <p className={"margin_left white_title_centering padding_top_mid"}>
          {t("brands_title")}
          </p>
          <Row>
            <Col>
              <a href="#henridelille">
                <p className={"white_text"}> {t("click_info")}</p>
                <img
                  className={"padding_large_smaller  black_border"}
                  src="/HD.png"
                  alt="Home Picture"
                  width="70%"
                />
              </a>
            </Col>
            <Col>
              <a href="#pierremarcel">
                <p className={"white_text"}>{t("click_info")}</p>
                <img
                  href="#pierremarcel"
                  className={"padding_large_smaller black_border"}
                  src="/PM.png"
                  alt="Home Picture"
                  width="70%"
                />
              </a>
            </Col>
          </Row>
        </Container>
      );
    } else {
      render_text = (
        <Row>
          <Col>
            <div className={"left_right_margin_large"}>
            <p>{t("about_henri1")}</p>
            <p>{t("about_henri2")}</p>
            <p>{t("about_henri3")}</p>
            <p>{t("about_henri4")}</p>
            <p>{t("about_henri5")}</p>
            <p>{t("about_henri6")}</p>
            </div>
            <br />
            <img className={"left_alignment"} src="/label.jpg" alt="Label" />

            <Container>
              <Row>
                <Col>
                  <img
                    className={"about_pic"}
                    src="/about/about1.jpg"
                    alt="Home Picture"
                  />
                </Col>
                <Col>
                  <img
                    className={"about_pic"}
                    src="/about/about3.jpg"
                    alt="Home Picture"
                  />
                </Col>
                <Col>
                  <img
                    className={"about_pic_larger"}
                    src="/about/about5.jpg"
                    alt="Home Picture"
                  />
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      );

      brands_box = (
        <Container className={"left_right_margin_large padding_large_background"}>
          <p className={"margin_left white_title_centering padding_top_mid"}>
          {t("brands_title")}
          </p>
          <Row>
            <Col>
              <a href="#henridelille">
                <p className={"white_text"}> {t("click_info")}</p>
                <img
                  className={"padding_large_smaller black_border"}
                  src="/HD.png"
                  alt="Home Picture"
                  width="50%"
                />
              </a>
            </Col>
            <Col>
              <a href="#pierremarcel">
                <p className={"white_text"}>{t("click_info")}</p>
                <img
                  href="#pierremarcel"
                  className={"padding_large_smaller black_border"}
                  src="/PM.png"
                  alt="Home Picture"
                  width="50%"
                />
              </a>
            </Col>
          </Row>
        </Container>
      );
    }
    return (
      <div id="home">
        <br />
        <br />
        <br />
        <Container>
          <Row>
            <Col>
              <h1 className={"title_hd"}>{companyName}</h1>
            </Col>
            <img
              className={"left_alignment"}
              src="/parts/be_leeuw.png"
              alt="Label"
              width="10%"
              height="10%"
            />
          </Row>
        </Container>
        <br />
        {render_text}

        <br />
        <br />

        {brands_box}
      </div>
    );
  }
}
export default withTranslation(["common"])(Home);
