import React, { Component } from "react";
import { foot_note } from "../helpers/GlobalVars";
import "../index.css";

export default class Footer extends Component {
  render() {
    return (
      <div>
        <hr />
        <p>{foot_note}</p>
      </div>
    );
  }
}
