//information on the company
export let companyName = "Henri Delille Violins";
export let companyEmail = "eaitc@pandora.com";
export let companyStreet= "Wazenaarstraat 16";
export let companyCity = "9050 Ledeberg";
export let companyProvince = "East-Flanders";
export let companyCountry = "Belgium";

export let companyPhone = "(+32) 923 23 577";

//question form
export let successContactMessage = "Thank you for your message! You have succesfully send the message. We will try to answer as soon as possible";
export let contact_invitation = "Please don't hesitate to ask any questions, reply time will be within a few hours.";

//about page of company
export let aboutTitle = "Tradition since 1997";
export let about = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.";
export let about_specific = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.";

//of pictures at the page of overview of the listed items
export let height = 200;
export let width = 200;

export let unit = "€";
export let defaultImage = "public/default_image.jpg";

//Homepage Intro
export let homeEnglish = "For more than 20 years, the Henri Delille string instruments stand for tradition, innovation and uncompromising quality. Each instrument is handcrafted by our master violin builders who they carry the heritage of many centuries of European violin building tradition. Each piece is chosen with great care and respect for the instrument.";
export let homeGerman = "Die “Henri Delille”-serie von Streichinstrumenten steht für hervorragende Qualität. Die Instrumente sind von Ausgebildeten Geigenbauern hergestellt und tragen das Erbe vieler Jahrhunderte europäischen Geigenbaus in sich. Jedes einzelne Instrument wird mit großer Sorgfalt ausgewählt.";
export let homeFrench = "Le nom ‘Henri Delille’, pour les instruments du quatuor à cordes, représente une gamme de qualité. Les instruments sont fabriqués par les maîtres luthiers qui, depuis plusieurs siècles, perpétuent l’héritage traditionnel de la construction européene. Chaque pièce est choisie avec un grand respect pour l’instrument et lui donne un label de grande qualité.";

//aboutUs
export let about_henri1 = "Over the past 20 years, the Henri Delille fine stringed instruments have found their origins in Belgium and are synonymous for tradition, innovation and uncompromising quality.";
export let about_henri2 = "Each Henri Delille branded instrument is treated with the greatest care and carries the heritage of many centuries of European violin building tradition. We follow every instrument throughout its journey from a magnificent tree to an exquisite fine stringed instrument.";
export let about_henri3 = "The journey starts with carefully selecting the seasoning tone wood and allowing the wood to dry naturally for at least 15 years. This gives our instruments their typical rich and warm sound.";
export let about_henri4 = "After this natural drying process, the instruments are then handcrafted by our master violin builders with great attention for detail in our ateliers.";
export let about_henri5 = "Once the core of the instrument is ready, a varnish will be applied. This original varnish recipe and  application technique has been a carefully guarded since the very beginning and give our instruments their signature look and feel.";
export let about_henri6 = "With over 20 years of experience, up till this day, Henri Delille Violins still continues its unrelenting quest to innovate and improve its instruments offering you only the very best instruments.";

export let foot_note = "© Henri Delille Violins";

//contactInformation


export let buttonColors = {
    "Default": "#81b71a",
    "Blue": "#00B1E1",
    "Cyan": "#37BC9B",
    "Green": "#8CC152",
    "Red": "#E9573F",
    "Yellow": "#F6BB42",
    "Grey": "hotpink"
};

export let bgColors = {
    "Default": "#81b71a",
    "Blue": "#00B1E1",
    "Cyan": "#37BC9B",
    "Green": "#8CC152",
    "Red": "#E9573F",
    "Yellow": "#F6BB42",
    "Grey": "#ebe8e8"
};

export let mobileSize = 500;
export let mediumSize = 800;
export let largeSize = 1000;