import React from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";

/**
 * Fallback component to show when pages are loading.
 */
const Loading = () => {
  return (
    <div className="d-flex">
      <Container className="m-auto">
        <Row className="justify-content-center align-content-center">
          <Col className="col-5 text-center">Loading ...</Col>
        </Row>
        <Row className="justify-content-center align-content-center">
          <Col className="col-1">
            <Spinner animation="grow" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Loading;
