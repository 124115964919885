import React, { Component } from "react";
import {
  companyName,
  homeEnglish,
  homeFrench,
  homeGerman,
  width,
  largeSize,
  mobileSize,
} from "../helpers/GlobalVars";
import { Col, Row } from "react-bootstrap";
import "../index.css";
import "./gallery.css";
import { withTranslation } from "react-i18next";

const slideImages = [
  "/pmgallery/back_1.jpeg",
  "/pmgallery/front_pm.jpeg",
  "/pmgallery/group1.JPG",
  "/pmgallery/head_1.jpeg",
];

class PMGallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      index_picture: 0,
      width: window.innerWidth,
    };
    console.log(window.innerWidth)
    this.incrPage = this.incrPage.bind(this);
    this.decrPage = this.decrPage.bind(this);
  }

  componentWillMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  // make sure to remove the listener
  // when the component is not mounted anymore
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  incrPage() {
    this.setState((state) => ({
      index_picture: (state.index_picture + 1) % (slideImages.length - 1),
    }));
  }

  decrPage() {
    let newIndex = this.state.index_picture - 1;
    if (newIndex < 0) {
      newIndex = slideImages.length - 1;
    }
    this.setState((state) => ({
      index_picture: newIndex,
    }));
  }

  getImage() {
    return slideImages[this.state.index_picture];
  }

  render() {
    const { width } = this.state;
    const isMobile = width <= mobileSize;
    const { t } = this.props;

    let picture_move;

    if (isMobile) {
      picture_move = (
        <Row>
          <Col sm>
            <img
              src={this.getImage()}
              onClick={this.incrPage}
              alt="Home Picture"
              width="70%"
            />
          </Col>
          <Col>
            <img
              className={"arrowL"}
              src="/gallery/arrowL.png"
              onClick={this.decrPage}
              alt="About Picture"
              width="10%"
            />
          </Col>
          <Col>
            <img
              className={"arrowR"}
              src="/gallery/arrowR.png"
              onClick={this.incrPage}
              alt="About Picture"
              width="10%"
            />
          </Col>
        </Row>
      );
    } else {
      picture_move = (
        <Row>
          <Col>
            <img
              className={"arrowL_large"}
              src="/gallery/arrowL.png"
              onClick={this.decrPage}
              alt="About Picture"
              width="10%"
            />
          </Col>
          <Col>
            <img
              src={this.getImage()}
              onClick={this.incrPage}
              alt="Home Picture"
              width="100%"
            />
          </Col>
          <Col>
            <img
              className={"arrowR_large"}
              src="/gallery/arrowR.png"
              onClick={this.incrPage}
              alt="About Picture"
              width="10%"
            />
          </Col>
        </Row>
      );
    }

    return (
      <div id="pmgallery" className="White">
        <br />
        <br />
        <br />
        <h1 className={"HomePageCompanyName"}>Pierre Marcel {t("gallery")}</h1>
        <p>{t("our_instruments")}</p>
        <p>
          {t("info_gallery_click")}
        </p>
        <br />
        {picture_move}
        <br />
      </div>
    );
  }
}

export default withTranslation(["common"])(PMGallery);
