import React, { Component } from "react";
import "../index.css";
import "./event.css";
import { Col, Row } from "react-bootstrap";
import { largeSize, mobileSize } from "../helpers/GlobalVars";

import { withTranslation } from "react-i18next";
class Event extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
    };
  }

  componentWillMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    const { t } = this.props;
    const { width } = this.state;
    const isFullScreen = width >= 900;
    let render_pic;
    let render_space;
    if (isFullScreen) {
      render_pic = <img src="/about/exhibition.jpg" alt="Home Picture" width="60%" />;
      render_space = (
        <>
          <br />
          <br />
        </>
      );
    } else {
      console.log("is not fullscreen");
      render_pic = (
        <img src="/about/exhibition.jpg" alt="Home Picture" width="90%" />
      );
      render_space = <></>;
    }

    return (
      <div id="event">
        <br />
        <br />
        <br />
        <h1 className={"HomePageCompanyName"}>{t("events")}</h1>

        <h4>{t("visit_exhibition")}</h4>
        <br />
        <Row>
          <Col>
            <br />
            {render_pic}
          </Col>
          <Col className={"Background"}>
            <br />
            {render_space}
            <a
              href="https://musik.messefrankfurt.com/frankfurt/en.html"
              target="_blank"
            >
              {t("exhibition_centre")} : Frankfurt Musikmesse - {t("germany")}
            </a>{" "}
            <p>Messe Frankfurt Venue GmbH</p>
            <p>BesucherservicePostfach 15 02 10</p>
            <p>{t("germany")}</p>
            <a
              href="http://www.cremonamusica.com/en/la-manifestazione/"
              target="_blank"
            >
              {t("exhibition_centre")} : Cremona Mondomusica - {t("italy")}
            </a>
            <p>CremonaFiere S.p.A</p>
            <p>Piazza Zelioli Lanzini</p>
            <p>126100 Cremona</p>
            <p>{t("italy")}</p>
            <a href="https://www.namm.org" target="_blank">
              {" "}
              The Namm Show - California
            </a>
            <p>C00 W Katella Avenue</p>
            <p>Anaheim CA 92802</p>
            <p>{t("usa")}</p>
            <br />
          </Col>
        </Row>
        <br />
      </div>
    );
  }
}

export default withTranslation(["common"])(Event);
