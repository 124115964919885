import { useTranslation } from "react-i18next";
import { NavDropdown } from "react-bootstrap";
import { langMap } from "../i18n";
import React from "react";

const LanguageDropdown = () => {
  const { i18n } = useTranslation();
  const changeLanguage = (lang) => i18n.changeLanguage(lang);

  return (
    <NavDropdown
      id="language-selector"
      alignRight
      variant="light"
      title={i18n.language.toLocaleUpperCase()}
    >
      {Array.from(langMap.entries())
        .filter(entry => entry[0] !== i18n.language)
        .map((entry, index) => {
          const [key, val] = entry;
          return (
            <NavDropdown.Item
              key={`lang-drop${index}`}
              eventKey={key}
              onSelect={changeLanguage}
            >
              {val}
            </NavDropdown.Item>
          );
        })}
    </NavDropdown>
  );
};
export default LanguageDropdown;
