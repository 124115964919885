import React, { Component } from "react";
import "../index.css";
import { withTranslation } from "react-i18next";

class HenriDelille extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
    };
  }

  componentWillMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  // make sure to remove the listener
  // when the component is not mounted anymore
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    const { t } = this.props;
    const { width } = this.state;
    const isFullScreen = width >= 1000;
    console.log(width);
    let picture_move;
    if (isFullScreen) {
      picture_move = (
        <div>
          <br />
          <br />
          <br />
          <h1 className={"HomePageCompanyName"}>Henri Delille</h1>
          <br />
          <img width="30%" src="/about/about5.jpg" alt="Home Picture" />
          <br />

          <br />
          <p className={"left_right_margin_large"}>{t("main_text_hd")}</p>
          <p>* {t("point1_hd")}</p>
          <p>* {t("point2_hd")}</p>
          <p>* {t("point3_hd")}</p>
          <p>* {t("point4_hd")}</p>
        </div>
      );
    } else {
      picture_move = (
        <div>
          <br />
          <br />
          <br />
          <h1 className={"HomePageCompanyName"}>Henri Delille</h1>
          <br />
          <img
            className={"main_pic"}
            src="/about/about5.jpg"
            alt="Home Picture"
          />
          <br />

          <br />
          <p className={"left_right_margin"}>{t("main_text_hd")}</p>
          <p>* {t("point1_hd")}</p>
          <p>* {t("point2_hd")}</p>
          <p>* {t("point3_hd")}</p>
          <p>* {t("point4_hd")}</p>
        </div>
      );
    }
    return (
      <div id="henridelille" className="White">
        {picture_move}
      </div>
    );
  }
}
export default withTranslation(["common"])(HenriDelille);
