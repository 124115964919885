import React, { Component } from "react";
import "../index.css";
import { withTranslation } from "react-i18next";

class PierreMarcel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
    };
  }

  componentWillMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    const { t } = this.props;
    const { width } = this.state;
    const isFullScreen = width >= 800;
    const isMobile = width < 400;
    let render_item;

    if (isFullScreen) {
      render_item = (
        <div>
          <img src="/pmgallery/group4.JPG" width="55%" alt="Home Picture" />
          <br />
          <br />
          <p className={"left_right_margin_large"}>{t("main_text_pm")}</p>
        </div>
      );
    } else if (isMobile) {
      render_item = (
        <div>
          <img src="/pmgallery/group4.JPG" width="60%" alt="Home Picture" />
          <br />
          <br />
          <p className={"middle_page_pm"}>{t("main_text_pm")}</p>
        </div>
      );
    } else {
      render_item = (
        <div>
          <img src="/pmgallery/group4.JPG" width="90%" alt="Home Picture" />
          <br />
          <br />
          <p className={"middle_page_pm"}>{t("main_text_pm")}</p>
        </div>
      );
    }

    return (
      <div id="pierremarcel">
        <br />
        <br />
        <br />
        <h1 className={"HomePageCompanyName"}>Pierre Marcel</h1>
        <br />

        {render_item}

        <p>* {t("point1_pm")}</p>
        <p>* {t("point2_pm")}</p>
        <p>* {t("point3_pm")}</p>
        <p>* {t("point4_pm")}</p>

        <br />
        <a href="#pmgallery">
          <p>Go to gallery</p>
        </a>
        <br />
        <img
          className={"margin_right"}
          src="/parts/vlag-van-belgie.png"
          alt="Home Picture"
          width="15%"
        />
        <img
          className={"margin_right"}
          src="/parts/eu.jpeg"
          alt="Home Picture"
          width="15%"
        />
        <br />
        <br />
      </div>
    );
  }
}
export default withTranslation(["common"])(PierreMarcel);
