import React, { Suspense } from "react";
import "./App.css";
import UpperNavBar from "./component/upperNavBar";
import Home from "./pages/home";
import Footer from "./pages/footer";
import Gallery from "./pages/gallery";
import Contact from "./pages/contact";
import Event from "./pages/event";
import PierreMarcel from "./pages/pierreMarcel";
import HenriDelille from "./pages/henriDelille";
import PMGallery from "./pages/pmGallery";
import Loading from "./component/loading";

function App() {
  return (
    <div className="App">
      <Suspense fallback={<Loading />}>
        <br />
        <br />
        <UpperNavBar />
        <br />
        <Home />
        <br />
        <hr />
        <PierreMarcel />
        <br />
        <hr />
        <PMGallery />
        <br />
        <hr />
        <HenriDelille />
        <br />
        <hr />
        <Gallery />
        <br />
        <hr />
        <Event />
        <br />
        <hr />
        <Contact />
        <Footer />
      </Suspense>
    </div>
  );
}
//<br/><hr/>
//<PierreMarcel/>

export default App;
