import React from "react";
import {
  Navbar,
  Nav,
  NavDropdown,
  Form,
  FormControl,
  Button,
} from "react-bootstrap";
import {
  companyName,
  companyAdress,
  companyEmail,
  companyPhone,
  contact_invitation,
  successContactMessage,
} from "../helpers/GlobalVars";
import "../index.css";
import LanguageDropdown from "./languageDropDown";
import { withTranslation } from "react-i18next";

class SideNavBar extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { t } = this.props;
    return (
      <Navbar bg="dark" variant="dark" expand="sm" fixed="top">
        <Navbar.Brand href="/">{companyName}</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link href="#home">{t("home")}</Nav.Link>
            <Nav.Link href="#pierremarcel">{t("pm")}</Nav.Link>
            <Nav.Link href="#henridelille">{t("hd")}</Nav.Link>
            <Nav.Link href="#event">{t("events")}</Nav.Link>
            <Nav.Link href="#contact">{t("contact")}</Nav.Link>
            <LanguageDropdown />
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default withTranslation(["common"])(SideNavBar);
