import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    debug: false,
    saveMissing: false,
    saveMissingTo: "current",
    updateMissing: true,
    backend: {
      loadPath: `${process.env.PUBLIC_URL}/locales/{{lng}}/{{ns}}.json`,
      addPath: `${process.env.PUBLIC_URL}/locales/add/{{lng}}/{{ns}}`,
    },
    interpolation: {
      escapeValue: false,
    },
    whitelist: ["en", "fr", "de"]//, "nl"]
  });

export const langMap = new Map([
  ["en", "English"],
  ["fr", "Français"],
  ["de", "Deutsch"],
  //["nl", "Nederlands"],
]);
export default i18n;
