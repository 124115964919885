import React, { Component } from "react";
import {
  companyName,
  companyEmail,
  companyPhone,
  companyCity,
  companyCountry,
  companyProvince,
  companyStreet,
} from "../helpers/GlobalVars";
import { Col, Row } from "react-bootstrap";
import "../index.css";
import { withTranslation } from "react-i18next";

class Contact extends Component {
  render() {
    const { t } = this.props;
    return (
      <div id="contact">
        <br />
        <br />
        <br />
        <div className={"contact_card"}>
          <br />
          <h1 className={"HomePageCompanyName"}>{t("contact")}</h1>
          <br />
          <Row>
            <Col>
              <img
                className={"padding_large"}
                src="/model/model2.jpg"
                alt="Home Picture"
              />
            </Col>
            <Col className={"left_alignment padding_large"}>
              <p className={"midsize_font"}>
                <b>{companyName}</b>
              </p>
              <p>{companyStreet}</p>
              <p>{companyCity}</p>
              <p>{t("our_province")}</p>
              <p>{t("our_country")}</p>
              <br />
              <br />
              <p>{t("telephone")} : {companyPhone}</p>
              <p>{t("email")} : {companyEmail}</p>
            </Col>
          </Row>

          <br />
          <br />
        </div>
      </div>
    );
  }
}
//onClick={()=> Linking.openURL('mailto:eaitc@pandora.be')}
export default withTranslation(["common"])(Contact);