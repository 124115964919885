import React, { Component } from "react";
import {
  companyName,
  homeEnglish,
  homeFrench,
  homeGerman,
  width,
} from "../helpers/GlobalVars";
import { Col, Row } from "react-bootstrap";
import "../index.css";
import "./gallery.css";
import { withTranslation } from "react-i18next";

class Gallery extends Component {
  render() {
    const { t } = this.props;
    return (
      <div id="gallery" className="White">
        <br />
        <br />
        <br />
        <h1 className={"HomePageCompanyName"}>Henri Delille {t("gallery")}</h1>
        <p>{t("our_models")}</p>
        <br />
        <Row>
          <Col>
            <img
              className={"image_half"}
              src="/gallery/violin1.jpg"
              alt="Home Picture"
            />
            <p>Amati {t("model")} 410</p>
            <p>{t("viola")}</p>
          </Col>
          <Col>
            <img
              className={"image_half"}
              src="/gallery/violin2.jpg"
              alt="Home Picture"
            />
            <p>Amati {t("model")} 1666</p>
            <p>{t("violin")}</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <img
              className={"image_half"}
              src="/gallery/viola1.jpg"
              alt="Home Picture"
            />
            <p>Bellozio {t("viola")} 395</p>
            <p>{t("viola")}</p>
          </Col>
          <Col>
            <img
              className={"image_half"}
              src="/gallery/viola2.jpg"
              alt="Home Picture"
            />
            <p>Bellogio {t("viola")} 395</p>
            <p>{t("viola")}</p>
          </Col>
        </Row>

        <br />
        <br />
      </div>
    );
  }
}

export default withTranslation(["common"])(Gallery);
